import { FlagModel, ValueModel } from '@zajno/common-mobx/viewModels';
import { DeepReadonly } from '@zajno/common/types';
import { computed, makeObservable, reaction } from 'mobx';
import { Disposable } from '@zajno/common/functions/disposer';

import { AppController } from 'app/controllers';
import { PremiumLevel, ProductData } from 'common/types/models';

export enum CampaignSelectorModes {
    Default = 'Campaigns',
    Premium = 'A-List',
}

export class CampaignSelectorViewModel extends Disposable {
    private isInitialized = new FlagModel(false);

    private readonly _productList = new ValueModel<DeepReadonly<ProductData[]>>();
    private readonly _mode = new ValueModel<CampaignSelectorModes>(CampaignSelectorModes.Default);
    private readonly _activeCampaign = new ValueModel<DeepReadonly<ProductData>>();

    constructor(initialProductsList: DeepReadonly<ProductData[]>) {
        super();
        // caching data from the build time
        this._productList.setValue(initialProductsList);
        this._activeCampaign.setValue(initialProductsList[0]);

        makeObservable(this, {
            productsList: computed,
            selectedProductsColors: computed,
        });

        this.disposer.add(
            reaction(
                () => this.productsList,
                (list) => this._activeCampaign.setValue(list[0]),
                { fireImmediately: true },
            ),
        );
    }

    public get currentMode() { return this._mode.value; }

    public get isPremiumUser() {
        return this.isInitialized.value && AppController.Instance.Users?.user?.premium_level === PremiumLevel.Premium;
    }

    public get productsList() {
        const level = this._mode.value === CampaignSelectorModes.Premium
            ? PremiumLevel.Premium
            : PremiumLevel.None;

        return this._productList.value.filter(p => p.availability_level === level);
    }

    public get activeCampaign() { return this._activeCampaign.value; }
    public get selectedProductsColors() { return this.productsList.map((c) => c.meta.color); }
    public get activeIndex() { return this.productsList.findIndex(c => c.id === this.activeCampaign.id); }

    public async initialize() {
        if (this.isInitialized.value) {
            return;
        }

        await AppController.Instance.initialize();

        this.isInitialized.setTrue();

        this.disposer.add(
            reaction(
                () => AppController.Instance.Products?.availableProducts,
                products => {
                    if (products) {
                        this._productList.setValue(products);
                    }
                },
                { fireImmediately : true },
            ),
        );

        this.disposer.add(
            reaction(
                () => AppController.Instance.Users?.user,
                user => {
                    if (user?.premium_level === PremiumLevel.Premium) {
                        this.setCurrentMode(CampaignSelectorModes.Premium);
                    }
                },
                { fireImmediately : true },
            ),
        );
    }

    public setCurrentMode = (mode: CampaignSelectorModes) => {
        this._mode.setValue(mode);
    };

    public setActiveCampaign = (id: string) => {
        const campaign = this.productsList.find(p => p.id === id);

        this._activeCampaign.setValue(campaign);
    };
}
