import React, { useEffect } from 'react';
import { DeepReadonly } from '@zajno/common/types';
import { observer } from 'mobx-react-lite';
import { useInView } from 'react-intersection-observer';

import './style.sass';

import { CampaignPoints } from './CampaignPoints';
import { CampaignBuyPanel } from './CampaignBuyPanel';
import { CampaignChart } from './CampaignChart';
import { CampaignSwitcher } from './CampaignSwitcher';
import { ProductData } from 'common/types/models/product';
import { PremiumLevel } from 'common/types/models';
import { useDisposableModelWithArgument } from 'app/hooks/useViewModel';
import { CampaignSelectorViewModel } from './viewModel';


type Props = {
    className?: string,
    initialProductsList: DeepReadonly<ProductData[]>,
};

export const CampaignSelector = observer(({ className, initialProductsList }: Props) => {
    const { ref, inView } = useInView({
        threshold: 0,
        triggerOnce: true,
    });

    const model = useDisposableModelWithArgument(CampaignSelectorViewModel, initialProductsList);

    const {
        currentMode,
        productsList,
        activeCampaign,
        activeIndex,
        isPremiumUser,
        selectedProductsColors,
        setActiveCampaign,
        setCurrentMode,
    } = model;

    if (!activeCampaign) {
        return null;
    }

    const selectedTheme = activeCampaign.meta.color;
    const isSelectedCampaignPremium = activeCampaign.availability_level === PremiumLevel.Premium;

    useEffect(() => {
        if (inView) {
            model.initialize();
        }
    }, [model, inView]);

    return (
        <div className={`campaign-selector ${className || ''}`} ref={ref}>
            <CampaignSwitcher
                className="campaign-selector__switcher"
                productsList={productsList}
                theme={selectedTheme}
                isPremiumUser={isPremiumUser}
                activeIndex={activeIndex}
                onSelectCampaign={setActiveCampaign}
                currentMode={currentMode}
                setCurrentMode={setCurrentMode}
            />
            <CampaignPoints
                className="campaign-selector__points"
                theme={selectedTheme}
                campaignMeta={activeCampaign.meta}
                isPremiumProduct={isSelectedCampaignPremium}
            />
            <CampaignChart
                className="campaign-selector__chart"
                activeIndex={activeIndex}
                campaignMeta={activeCampaign.meta}
                campaignsColors={selectedProductsColors}
                isPremiumProduct={isSelectedCampaignPremium}
            />
            <CampaignBuyPanel
                className="campaign-selector__buy-panel"
                activeCampaign={activeCampaign}
            />
        </div>
    );
});
