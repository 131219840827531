import React from 'react';

type Props = {
    className?: string,
    fill: string,
};

export const CheckmarkIcon = ({ className, fill }: Props) => {
    return (
        <svg className={`${className} || ''`} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M18.2071 7.79289C18.5976 8.18342 18.5976 8.81658 18.2071 9.20711L10 17.4142L5.29289 12.7071C4.90237 12.3166 4.90237 11.6834 5.29289 11.2929C5.68342 10.9024 6.31658 10.9024 6.70711 11.2929L10 14.5858L16.7929 7.79289C17.1834 7.40237 17.8166 7.40237 18.2071 7.79289Z" fill={fill}/>
        </svg>
    );
};
