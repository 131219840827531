type Props = {
    colors: string[],
    itemClassName: string,
    activeIndex: number,
};

export const ChartSVG = ({ colors, itemClassName, activeIndex }: Props) => {
    const isFirstItem = activeIndex === 0;
    const isSecondItem = activeIndex === 1;
    const isThirdItem = activeIndex === 2;
    const isFourthItem = activeIndex === 3;

    return (
        <>
            <svg className="desktop-chart" width="703" height="304" viewBox="0 0 703 304" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.4">
                    <path opacity="0.2" d="M3 13H702" stroke="#FDF7EF" strokeLinecap="round" strokeDasharray="10 10"/>
                    <path opacity="0.2" d="M2 71H701" stroke="#FDF7EF" strokeLinecap="round" strokeDasharray="10 10"/>
                    <path opacity="0.2" d="M2 129H701" stroke="#FDF7EF" strokeLinecap="round" strokeDasharray="10 10"/>
                    <path opacity="0.2" d="M2 187H701" stroke="#FDF7EF" strokeLinecap="round" strokeDasharray="10 10"/>
                    <path opacity="0.2" d="M2 245H701" stroke="#FDF7EF" strokeLinecap="round" strokeDasharray="10 10"/>
                    <path opacity="0.2" d="M2 303H701" stroke="#FDF7EF" strokeLinecap="round"/>
                </g>
                <g className={itemClassName}>
                    <path className={`gradient-bg ${isFourthItem ? 'active' : ''}`} d="M16.4823 25.2563L1 303H700.5V4.5L685.428 12.5944C678.024 16.5703 669.081 16.3845 661.849 12.1044L659.325 10.6108C652.825 6.76389 644.894 6.20125 637.916 9.09209L622.191 15.6065C616.905 17.7965 611.01 18.0258 605.57 16.2531L575.809 6.55579C571.68 5.2105 567.264 5.01053 563.031 5.9772L513.961 17.1822C508.799 18.3609 503.392 17.7995 498.582 15.5856L482.297 8.08919C477.255 5.76838 471.565 5.26676 466.195 6.66974L425.929 17.1898C421.408 18.3709 416.641 18.2067 412.212 16.7174L372.638 3.40917C366.185 1.23926 359.115 1.91177 353.187 5.25927L330.853 17.8711C324.049 21.7134 315.801 22.0027 308.744 18.6465L287.337 8.46531C281.971 5.91294 275.848 5.44231 270.155 7.14451L236.664 17.1572C232.626 18.3644 228.341 18.487 224.241 17.5127L185.993 8.42404C182.061 7.48982 177.958 7.56343 174.062 8.63805L141.868 17.5193C138.336 18.4935 134.628 18.6463 131.029 17.9661L76.741 7.70696C74.2625 7.23858 71.7257 7.16384 69.2239 7.4855L40.6813 11.1553C38.9071 11.3834 37.1067 11.3039 35.3595 10.9204C25.9946 8.86467 17.016 15.6834 16.4823 25.2563Z" fill="url(#paint0_linear_1928_9991)"/>
                    <path className="chart-line" d="M1 303L16.4823 25.2563C17.016 15.6834 25.9946 8.86467 35.3595 10.9204V10.9204C37.1067 11.3039 38.9071 11.3834 40.6813 11.1553L69.2239 7.4855C71.7257 7.16384 74.2625 7.23858 76.741 7.70696L131.029 17.9661C134.628 18.6463 138.336 18.4935 141.868 17.5193L174.062 8.63805C177.958 7.56343 182.061 7.48982 185.993 8.42404L224.241 17.5127C228.341 18.487 232.626 18.3644 236.664 17.1572L270.155 7.14451C275.848 5.44231 281.971 5.91294 287.337 8.46531L308.744 18.6465C315.801 22.0027 324.049 21.7134 330.853 17.8711L353.187 5.25927C359.115 1.91177 366.185 1.23926 372.638 3.40917L412.212 16.7174C416.641 18.2067 421.408 18.3709 425.929 17.1898L466.195 6.66974C471.565 5.26676 477.255 5.76838 482.297 8.08919L498.582 15.5856C503.392 17.7995 508.799 18.3609 513.961 17.1822L563.031 5.9772C567.264 5.01053 571.68 5.2105 575.809 6.55579L605.57 16.2531C611.01 18.0258 616.905 17.7965 622.191 15.6065L637.916 9.09209C644.894 6.20125 652.825 6.76389 659.325 10.6108L661.849 12.1044C669.081 16.3845 678.024 16.5703 685.428 12.5944L700.5 4.5" stroke={isFourthItem ? colors[3] : '#404040'}/>
                </g>
                <g className={itemClassName}>
                    <path className={`gradient-bg ${isThirdItem ? 'active' : ''}`} d="M23.8752 77.0485L1 303H700.5V63L665.774 74.7147C661.354 76.2059 656.595 76.3765 652.08 75.2058L612.688 64.9932C607.696 63.6989 602.419 64.0472 597.641 65.9864L581.188 72.6631C574.432 75.4043 566.797 74.9265 560.437 71.3645L553.834 67.6673C548.482 64.6701 542.186 63.836 536.238 65.3364L500.418 74.372C493.79 76.044 486.763 74.8101 481.101 70.9798L480.907 70.8486C475.829 67.4137 469.629 66.0521 463.58 67.043L419.72 74.2269C416.604 74.7373 413.418 74.6277 410.344 73.9045L371.45 64.753C366.604 63.6126 361.522 64.0079 356.91 65.884L342.927 71.572C337.498 73.7807 331.447 73.9259 325.918 71.9805L313.912 67.7561C309.72 66.281 305.2 65.999 300.857 66.9414L260.889 75.6137C256.706 76.5214 252.356 76.2938 248.291 74.9546L219.048 65.3215C214.456 63.8092 209.516 63.7184 204.872 65.0611L178.774 72.6075C173.425 74.1542 167.705 73.7929 162.593 71.5855L157.949 69.5805C151.916 66.9749 145.077 66.9579 139.03 69.5334L128.706 73.9307C124.024 75.9251 118.828 76.3793 113.87 75.2275L77.2909 66.7292C73.8077 65.92 70.1877 65.8993 66.6955 66.6688L46.5214 71.1139C43.9482 71.6809 41.255 71.1944 39.0427 69.7629C32.8531 65.7579 24.6178 69.7136 23.8752 77.0485Z" fill="url(#paint1_linear_1928_9991)"/>
                    <path className="chart-line" d="M1 303L23.8753 77.0485C24.6178 69.7136 32.8531 65.7579 39.0427 69.7629V69.7629C41.255 71.1944 43.9482 71.6809 46.5214 71.1139L66.6955 66.6688C70.1877 65.8993 73.8077 65.92 77.2909 66.7292L113.87 75.2275C118.828 76.3793 124.024 75.9251 128.706 73.9307L139.03 69.5334C145.077 66.9579 151.916 66.9749 157.949 69.5805L162.593 71.5855C167.705 73.7929 173.425 74.1542 178.774 72.6075L204.872 65.0611C209.516 63.7184 214.456 63.8092 219.048 65.3215L248.291 74.9546C252.356 76.2938 256.706 76.5214 260.889 75.6137L300.857 66.9414C305.2 65.999 309.72 66.281 313.912 67.7561L325.918 71.9805C331.447 73.9259 337.498 73.7807 342.927 71.572L356.91 65.884C361.522 64.0079 366.604 63.6126 371.45 64.753L410.344 73.9045C413.418 74.6277 416.604 74.7373 419.72 74.2269L463.58 67.043C469.629 66.0521 475.829 67.4137 480.907 70.8486L481.101 70.9798C486.763 74.8101 493.79 76.044 500.418 74.372L536.238 65.3364C542.186 63.836 548.482 64.6701 553.834 67.6673L560.437 71.3645C566.797 74.9265 574.432 75.4043 581.187 72.6631L597.641 65.9864C602.419 64.0472 607.696 63.6989 612.688 64.9932L652.08 75.2058C656.595 76.3765 661.354 76.2059 665.774 74.7147L700.5 63" stroke={isThirdItem ? colors[2] : '#404040'}/>
                </g>
                <g className={itemClassName}>
                    <path className={`gradient-bg ${isSecondItem ? 'active' : ''}`} d="M23.492 147.851L1 303H701.5V119L658.167 133.583C652.585 135.462 646.507 135.222 641.09 132.91L615.935 122.173C611.111 120.115 605.746 119.692 600.66 120.971L532.191 138.189C526.285 139.674 520.036 138.859 514.71 135.909L497.82 126.554C493.08 123.929 487.591 122.985 482.246 123.876L425.692 133.301C422.919 133.764 420.086 133.735 417.323 133.217L378.097 125.862C375.061 125.293 371.944 125.315 368.916 125.927L334.629 132.862C330.928 133.61 327.104 133.475 323.466 132.467L303.956 127.065C299.108 125.722 293.961 125.937 289.242 127.679L262.446 137.567C257.304 139.465 251.667 139.546 246.473 137.795L215.143 127.238C210.805 125.777 206.139 125.586 201.696 126.689L140.352 141.919C137.476 142.634 134.492 142.809 131.551 142.436L69.8452 134.614C66.6478 134.209 63.4016 134.452 60.3001 135.328L45.5976 139.483C43.2638 140.143 40.7781 140.019 38.5217 139.13C31.8717 136.51 24.5175 140.777 23.492 147.851Z" fill="url(#paint2_linear_1928_9991)"/>
                    <path className="chart-line" d="M1 303L23.492 147.851C24.5175 140.777 31.8717 136.51 38.5217 139.13V139.13C40.7781 140.019 43.2638 140.143 45.5976 139.483L60.3001 135.328C63.4016 134.452 66.6478 134.209 69.8452 134.614L131.551 142.436C134.492 142.809 137.476 142.634 140.352 141.919L201.696 126.689C206.139 125.586 210.805 125.777 215.143 127.238L246.473 137.795C251.667 139.546 257.304 139.465 262.446 137.567L289.242 127.679C293.961 125.937 299.108 125.722 303.956 127.065L323.466 132.467C327.104 133.475 330.928 133.61 334.629 132.862L368.916 125.927C371.944 125.315 375.061 125.293 378.097 125.862L417.323 133.217C420.086 133.735 422.919 133.764 425.692 133.301L482.246 123.876C487.591 122.985 493.08 123.929 497.82 126.554L514.71 135.909C520.036 138.859 526.285 139.674 532.191 138.189L600.66 120.971C605.746 119.692 611.111 120.115 615.935 122.173L641.09 132.91C646.507 135.222 652.585 135.462 658.167 133.583L701.5 119" stroke={isSecondItem ? colors[1] : '#404040'}/>
                </g>
                <g className={itemClassName}>
                    <path className={`gradient-bg ${isFirstItem ? 'active' : ''}`} d="M17.2805 226.214L1 302.5L700.5 302V216L640.083 225.867C635.552 226.607 630.905 226.032 626.691 224.21L613.63 218.564C609.745 216.885 605.485 216.262 601.282 216.761L519.345 226.473C516.406 226.821 513.427 226.623 510.56 225.888L478.575 217.688C474.27 216.584 469.742 216.698 465.498 218.016L443.321 224.902C438.912 226.272 434.2 226.34 429.753 225.099L405.523 218.337C400.113 216.827 394.345 217.262 389.224 219.567L380.671 223.415C375.524 225.731 369.726 226.159 364.295 224.623L338.638 217.371C335.438 216.467 332.086 216.237 328.793 216.697L260.318 226.252C256.787 226.744 253.191 226.444 249.791 225.373L225.752 217.802C221.995 216.619 218.005 216.378 214.134 217.102L168.652 225.599C163.775 226.511 158.736 225.888 154.227 223.817L143.295 218.796C139.313 216.967 134.907 216.263 130.553 216.76L48.1277 226.176C43.4241 226.713 38.6666 225.848 34.4539 223.688L28.7543 220.765C24.0685 218.363 18.3796 221.064 17.2805 226.214Z" fill="url(#paint3_linear_1928_9991)"/>
                    <path className="chart-line" d="M1.5 303L17.7805 226.714C18.8796 221.564 24.5685 218.863 29.2543 221.265L34.9539 224.188C39.1666 226.348 43.9241 227.213 48.6277 226.676L131.053 217.26C135.407 216.763 139.813 217.467 143.795 219.296L154.727 224.317C159.236 226.388 164.275 227.011 169.152 226.099L214.634 217.602C218.505 216.878 222.495 217.119 226.252 218.302L250.291 225.873C253.691 226.944 257.287 227.244 260.818 226.752L329.293 217.197C332.586 216.737 335.938 216.967 339.138 217.871L364.795 225.123C370.226 226.659 376.024 226.231 381.171 223.915L389.724 220.067C394.845 217.762 400.613 217.327 406.023 218.837L430.253 225.599C434.7 226.84 439.412 226.772 443.821 225.402L465.998 218.516C470.242 217.198 474.77 217.084 479.075 218.188L511.06 226.388C513.927 227.123 516.906 227.321 519.845 226.973L601.782 217.261C605.985 216.762 610.245 217.385 614.13 219.064L627.191 224.71C631.405 226.532 636.052 227.107 640.583 226.367L701 216.5" stroke={isFirstItem ? colors[0] : '#404040'}/>
                </g>
                <defs>
                    <linearGradient id="paint0_linear_1928_9991" x1="351.278" y1="-842.789" x2="348.89" y2="302.996" gradientUnits="userSpaceOnUse">
                        <stop stopColor={colors[3]}/>
                        <stop offset="1" stopColor={colors[3]} stopOpacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear_1928_9991" x1="351.278" y1="-612.118" x2="349.755" y2="302.998" gradientUnits="userSpaceOnUse">
                        <stop stopColor={colors[2]}/>
                        <stop offset="1" stopColor={colors[2]} stopOpacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint2_linear_1928_9991" x1="351.778" y1="-392.793" x2="350.899" y2="303" gradientUnits="userSpaceOnUse">
                        <stop stopColor={colors[1]}/>
                        <stop offset="1" stopColor={colors[1]} stopOpacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint3_linear_1928_9991" x1="351.278" y1="-24.5981" x2="351.083" y2="302.5" gradientUnits="userSpaceOnUse">
                        <stop stopColor={colors[0]}/>
                        <stop offset="1" stopColor={colors[0]} stopOpacity="0"/>
                    </linearGradient>
                </defs>
            </svg>
            <svg className="tablet-chart" width="210" height="163" viewBox="0 0 210 163" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.1" d="M1 7.66553H209" stroke="#FDF7EF" strokeWidth="0.5" strokeDasharray="4 4"/>
                <path opacity="0.1" d="M1 59.2954H209" stroke="#FDF7EF" strokeWidth="0.5" strokeDasharray="4 4"/>
                <path opacity="0.1" d="M1 110.925H209" stroke="#FDF7EF" strokeWidth="0.5" strokeDasharray="4 4"/>
                <path opacity="0.2" d="M1 162.555L209 162.555" stroke="#FDF7EF" strokeWidth="0.5"/>

                <g className={itemClassName}>
                    <path className="chart-line" d="M1 162.555L5.73304 9.82117C5.81402 7.2081 8.46202 5.46611 10.8936 6.42627V6.42627C11.6053 6.70729 12.3856 6.76544 13.1311 6.59303L17.4124 5.60287C20.6589 4.85202 24.0506 5.02243 27.2055 6.09489L34.7618 8.66355C38.9047 10.0718 43.438 9.77006 47.3577 7.82505V7.82505C51.668 5.68616 56.6991 5.54353 61.1236 7.43477L62.9396 8.21101C66.8615 9.88739 71.3333 9.68719 75.0897 7.66703L77.5711 6.33252C81.1729 4.39552 85.5948 4.87109 88.7023 7.52969V7.52969C92.6554 10.9117 98.5511 10.6594 102.201 6.95201L102.552 6.59479C105.946 3.14807 111.261 2.48224 115.4 4.98559L118.339 6.76367C122.573 9.32494 127.808 9.5992 132.286 7.49443L135.949 5.77304C139.396 4.15295 143.469 4.66942 146.403 7.09869V7.09869C149.202 9.41594 153.05 10.0012 156.411 8.6207L163.745 5.60802C167.564 4.03956 171.896 4.3442 175.457 6.43165L178.253 8.07044C181.142 9.76356 184.763 9.56368 187.448 7.56293L189.423 6.09077C191.695 4.39757 194.876 4.6786 196.816 6.74394V6.74394C198.945 9.00983 202.515 9.09654 204.751 6.93663L208.703 3.11889" stroke={isFourthItem ? colors[3] : '#404040'}/>
                    <path className={`gradient-bg ${isFourthItem ? 'active' : ''}`} d="M5.73304 9.82117L1 162.555H208.703V3.11889L204.751 6.93663C202.515 9.09654 198.945 9.00983 196.816 6.74394C194.876 4.6786 191.695 4.39757 189.423 6.09077L187.448 7.56293C184.763 9.56368 181.142 9.76356 178.253 8.07044L175.457 6.43165C171.896 4.3442 167.564 4.03956 163.745 5.60802L156.411 8.6207C153.05 10.0012 149.202 9.41594 146.403 7.09869C143.469 4.66942 139.396 4.15295 135.949 5.77304L132.286 7.49443C127.808 9.5992 122.573 9.32494 118.339 6.76367L115.4 4.98559C111.261 2.48224 105.946 3.14807 102.552 6.59479L102.201 6.95201C98.5511 10.6594 92.6554 10.9117 88.7023 7.52969C85.5948 4.87109 81.1729 4.39552 77.5712 6.33252L75.0897 7.66703C71.3333 9.68719 66.8615 9.88739 62.9396 8.21101L61.1236 7.43477C56.6991 5.54353 51.668 5.68616 47.3577 7.82505C43.438 9.77006 38.9047 10.0718 34.7618 8.66355L27.2055 6.09489C24.0506 5.02243 20.6589 4.85202 17.4124 5.60287L13.1311 6.59303C12.3856 6.76544 11.6053 6.70729 10.8936 6.42627C8.46202 5.46611 5.81402 7.2081 5.73304 9.82117Z" fill="url(#paint0_linear_1928_10034)"/>

                </g>
                <g className={itemClassName}>
                    <path className="chart-line" d="M1 162.556L7.93664 39.3059C8.0593 37.1264 10.774 36.2063 12.1965 37.862V37.862C12.8705 38.6464 13.9677 38.9171 14.929 38.536L18.8976 36.9631C20.9392 36.1539 23.2163 36.1749 25.2427 37.0218L32.785 40.1737C35.2563 41.2065 38.0912 40.8389 40.2173 39.2099L41.211 38.4486C43.324 36.8297 46.263 36.8394 48.3652 38.4723V38.4723C50.1719 39.8757 52.6304 40.0986 54.6601 39.0429L57.9297 37.3422C61.4885 35.4911 65.7508 35.609 69.2018 37.6539L70.3758 38.3496C74.0973 40.5548 78.6267 40.9243 82.6564 39.3514L88.2434 37.1708C90.6112 36.2466 93.2774 36.5036 95.4252 37.8629V37.8629C98.2012 39.6199 101.77 39.4996 104.421 37.5595L104.806 37.2781C107.274 35.4717 110.511 35.0957 113.328 36.288L118.13 38.3204C121.88 39.9077 126.064 40.1461 129.97 38.9952L137.619 36.7416C139.761 36.1107 142.072 36.8211 143.489 38.5459V38.5459C145.088 40.4909 147.793 41.12 150.086 40.0797L158.387 36.3129C160.967 35.1423 164.003 35.6995 165.999 37.7101V37.7101C168.359 40.0872 172.101 40.3844 174.806 38.4096L176.173 37.4118C178.747 35.5326 182.14 35.2022 185.029 36.5493L188.763 38.2907C193.522 40.5105 199.076 40.2074 203.566 37.4827L208.703 34.3656" stroke={isThirdItem ? colors[2] : '#404040'}/>
                    <path className={`gradient-bg ${isThirdItem ? 'active' : ''}`} d="M7.93664 39.3059L1 162.556H208.703V34.3656L203.566 37.4827C199.076 40.2074 193.522 40.5105 188.763 38.2907L185.029 36.5493C182.14 35.2022 178.747 35.5326 176.173 37.4118L174.806 38.4096C172.101 40.3844 168.359 40.0872 165.999 37.7101C164.003 35.6995 160.967 35.1423 158.387 36.3129L150.086 40.0797C147.793 41.12 145.088 40.4909 143.489 38.5459C142.072 36.8211 139.761 36.1107 137.619 36.7416L129.97 38.9952C126.064 40.1461 121.88 39.9077 118.13 38.3204L113.328 36.288C110.511 35.0957 107.274 35.4717 104.806 37.2781L104.421 37.5595C101.77 39.4996 98.2012 39.6199 95.4252 37.8629C93.2774 36.5036 90.6112 36.2466 88.2434 37.1708L82.6564 39.3514C78.6267 40.9243 74.0973 40.5548 70.3758 38.3496L69.2018 37.6539C65.7508 35.609 61.4885 35.4911 57.9297 37.3422L54.6601 39.0429C52.6304 40.0986 50.1719 39.8757 48.3652 38.4723C46.263 36.8394 43.324 36.8297 41.211 38.4486L40.2173 39.2099C38.0912 40.8389 35.2563 41.2065 32.785 40.1737L25.2427 37.0218C23.2163 36.1749 20.9392 36.1539 18.8976 36.9631L14.929 38.536C13.9677 38.9171 12.8705 38.6464 12.1965 37.862C10.774 36.2063 8.0593 37.1264 7.93664 39.3059Z" fill="url(#paint1_linear_1928_10034)"/>
                </g>
                <g className={itemClassName}>
                    <path className="chart-line" d="M1 162.554L7.91583 76.7415C8.07422 74.7762 10.3121 73.731 11.9208 74.8709V74.8709C12.6915 75.4171 13.7015 75.491 14.5435 75.063L17.4449 73.588C19.0982 72.7475 20.9938 72.5131 22.8021 72.9254L33.2766 75.3138C38.3452 76.4695 43.6521 75.9482 48.3989 73.8282L56.0192 70.425C60.1491 68.5806 64.9209 68.879 68.7889 71.2235L69.5727 71.6985C73.6619 74.1771 78.8147 74.0711 82.7985 71.4264L83.589 70.9016C86.7779 68.7847 90.8544 68.5319 94.2804 70.2385V70.2385C96.9064 71.5466 99.9543 71.7193 102.711 70.7163L105.668 69.6407C109.512 68.242 113.718 68.1923 117.595 69.4998L119.074 69.9989C123.489 71.4876 128.256 71.5687 132.718 70.231L140.302 67.9573C144.027 66.8406 148.064 67.8548 150.819 70.5993V70.5993C153.936 73.7051 158.649 74.5597 162.659 72.7461L174.853 67.2302C178.875 65.4111 183.571 65.9536 187.072 68.6416V68.6416C190.984 71.645 196.338 71.9409 200.556 69.3869L209 64.2754" stroke={isSecondItem ? colors[1] : '#404040'} />
                    <path className={`gradient-bg ${isSecondItem ? 'active' : ''}`} d="M7.91583 76.7415L1 162.554H209V64.2754L200.556 69.3869C196.338 71.9409 190.984 71.645 187.072 68.6416C183.571 65.9536 178.875 65.4111 174.853 67.2302L162.659 72.7461C158.649 74.5597 153.936 73.7051 150.819 70.5993C148.064 67.8548 144.027 66.8406 140.302 67.9573L132.718 70.231C128.256 71.5687 123.489 71.4876 119.074 69.9989L117.595 69.4998C113.718 68.1923 109.512 68.242 105.668 69.6407L102.711 70.7163C99.9543 71.7193 96.9064 71.5466 94.2803 70.2385C90.8544 68.5319 86.7779 68.7847 83.589 70.9016L82.7985 71.4264C78.8147 74.0711 73.6619 74.1771 69.5727 71.6985L68.7889 71.2235C64.9209 68.879 60.1491 68.5806 56.0192 70.425L48.3989 73.8282C43.6521 75.9482 38.3452 76.4695 33.2766 75.3138L22.8021 72.9254C20.9938 72.5131 19.0982 72.7475 17.4449 73.588L14.5435 75.063C13.7015 75.491 12.6915 75.4171 11.9208 74.8709C10.3121 73.731 8.07422 74.7762 7.91583 76.7415Z" fill="url(#paint2_linear_1928_10034)"/>
                </g>
                <g className={itemClassName}>
                    <path className="chart-line" d="M1.14844 162.556L6.05798 121.175C6.29631 119.166 8.7113 118.274 10.1983 119.645V119.645C11.9574 121.268 14.3899 121.944 16.734 121.462L37.4114 117.213C40.0552 116.67 42.8028 117.354 44.8835 119.073V119.073C47.2592 121.036 50.4802 121.632 53.4012 120.651L60.2282 118.356C64.0193 117.082 68.1734 117.48 71.6532 119.452V119.452C74.8001 121.235 78.5125 121.738 82.0206 120.858L95.3622 117.509C98.3289 116.764 101.465 117.118 104.191 118.504L107.344 120.107C109.883 121.398 112.944 121.075 115.158 119.283V119.283C117.362 117.5 120.405 117.171 122.939 118.443L125.202 119.579C128.504 121.237 132.413 121.151 135.638 119.349L135.847 119.232C139.085 117.424 142.992 117.273 146.359 118.826L148.705 119.907C151.974 121.415 155.643 121.824 159.164 121.073L176.84 117.304C179.665 116.702 182.612 117.378 184.893 119.151V119.151C187.378 121.084 190.642 121.703 193.663 120.816L208.851 116.354" stroke={isFirstItem ? colors[0] : '#404040'}/>
                    <path className={`gradient-bg ${isFirstItem ? 'active' : ''}`} d="M5.90954 120.907L1 162.288L208.703 162.021V116.086L193.515 120.548C190.494 121.436 187.23 120.816 184.744 118.883C182.464 117.11 179.517 116.434 176.692 117.037L159.015 120.805C155.495 121.556 151.826 121.147 148.557 119.64L146.211 118.558C142.843 117.005 138.936 117.156 135.699 118.965L135.49 119.081C132.264 120.883 128.356 120.969 125.054 119.312L122.791 118.176C120.257 116.904 117.213 117.232 115.01 119.016C112.796 120.808 109.735 121.13 107.196 119.84L104.043 118.236C101.316 116.85 98.1804 116.497 95.2138 117.241L81.8722 120.59C78.3641 121.471 74.6517 120.967 71.5048 119.184C68.025 117.213 63.8708 116.814 60.0797 118.089L53.2528 120.383C50.3317 121.365 47.1108 120.768 44.7351 118.805C42.6544 117.086 39.9068 116.402 37.263 116.946L16.5855 121.195C14.2415 121.676 11.8089 121 10.0499 119.378C8.56287 118.006 6.14787 118.898 5.90954 120.907Z" fill="url(#paint3_linear_1928_10034)"/>
                </g>

                <defs>
                    <linearGradient id="paint0_linear_1928_10034" x1="105.008" y1="-449.439" x2="102.715" y2="162.547" gradientUnits="userSpaceOnUse">
                        <stop stopColor={colors[3]}/>
                        <stop offset="1" stopColor={colors[3]} stopOpacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear_1928_10034" x1="105.008" y1="-326.232" x2="103.545" y2="162.552" gradientUnits="userSpaceOnUse">
                        <stop stopColor={colors[2]}/>
                        <stop offset="1" stopColor={colors[2]} stopOpacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint2_linear_1928_10034" x1="105.157" y1="-209.086" x2="104.312" y2="162.553" gradientUnits="userSpaceOnUse">
                        <stop stopColor={colors[1]}/>
                        <stop offset="1" stopColor={colors[1]} stopOpacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint3_linear_1928_10034" x1="105.008" y1="-12.423" x2="104.821" y2="162.288" gradientUnits="userSpaceOnUse">
                        <stop stopColor={colors[0]}/>
                        <stop offset="1" stopColor={colors[0]} stopOpacity="0"/>
                    </linearGradient>
                </defs>
            </svg>
            <svg className="mobile-chart" width="270" height="120" viewBox="0 0 270 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.1" d="M1 6.49219H269" stroke="#FDF7EF" strokeWidth="0.5" strokeDasharray="4 4"/>
                <path opacity="0.1" d="M1 44.125H269" stroke="#FDF7EF" strokeWidth="0.5" strokeDasharray="4 4"/>
                <path opacity="0.1" d="M1 81.7539H269" stroke="#FDF7EF" strokeWidth="0.5" strokeDasharray="4 4"/>
                <path opacity="0.2" d="M1 119.383L269 119.383" stroke="#FDF7EF" strokeWidth="0.5"/>

                <g className={itemClassName}>
                    <path className="chart-line" d="M1 118.841L6.93327 9.72243C7.13172 6.07266 10.5634 3.47705 14.1294 4.27953V4.27953C14.8082 4.43228 15.5086 4.46393 16.1985 4.37301L24.6767 3.25551C27.2394 2.91772 29.8399 2.99616 32.3776 3.48781L47.2365 6.36647C50.9209 7.08025 54.7213 6.92007 58.3325 5.8988L63.4062 4.46393C67.3893 3.33748 71.596 3.26036 75.6178 4.24008L82.3952 5.89111C86.5893 6.91281 90.9814 6.78441 95.1086 5.51943L100.259 3.94071C104.821 2.54258 109.744 2.92794 114.032 5.01895V5.01895C119.666 7.76589 126.337 7.5067 131.762 4.36641V4.36641C136.53 1.60602 142.296 1.03163 147.505 2.82732L153.98 5.05943C158.503 6.61886 163.389 6.79067 168.011 5.55279L175.556 3.53194C179.816 2.39106 184.342 2.79775 188.33 4.67969V4.67969C192.134 6.47496 196.434 6.93021 200.53 5.97138L211.876 3.31535C216.206 2.30172 220.732 2.51108 224.95 3.92006L229.66 5.49312C233.438 6.75535 237.548 6.59244 241.215 5.03508L244.174 3.77836C247.15 2.51423 250.553 2.75919 253.318 4.43661V4.43661C256.392 6.30217 260.23 6.38293 263.38 4.64839L268.617 1.76496" stroke={isFourthItem ? colors[3] : '#404040'}/>
                    <path className={`gradient-bg ${isFourthItem ? 'active' : ''}`} d="M6.93327 9.72243L1 118.841H268.617V1.76496L263.38 4.64839C260.23 6.38293 256.392 6.30217 253.318 4.43661C250.553 2.75919 247.15 2.51423 244.174 3.77836L241.215 5.03508C237.548 6.59244 233.438 6.75535 229.66 5.49312L224.95 3.92006C220.732 2.51108 216.206 2.30172 211.876 3.31535L200.53 5.97138C196.434 6.93021 192.134 6.47496 188.33 4.67969C184.342 2.79775 179.816 2.39106 175.556 3.53194L168.011 5.55279C163.389 6.79067 158.503 6.61886 153.98 5.05943L147.505 2.82732C142.296 1.03163 136.53 1.60602 131.762 4.36641C126.337 7.5067 119.666 7.76589 114.032 5.01895C109.744 2.92794 104.821 2.54258 100.259 3.94071L95.1086 5.51943C90.9814 6.78441 86.5893 6.91281 82.3952 5.89111L75.6178 4.24008C71.596 3.26036 67.3893 3.33748 63.4062 4.46393L58.3325 5.8988C54.7213 6.92007 50.9209 7.08025 47.2365 6.36647L32.3776 3.48781C29.8399 2.99616 27.2394 2.91772 24.6767 3.25551L16.1985 4.37301C15.5086 4.46393 14.8082 4.43228 14.1294 4.27953C10.5634 3.47705 7.13172 6.07266 6.93327 9.72243Z" fill="url(#paint0_linear_4473_13016)"/>
                </g>
                <g className={itemClassName}>
                    <path className="chart-line" d="M1 118.842L9.76322 30.1036C10.04 27.3012 13.1998 25.8007 15.5465 27.3574V27.3574C16.3964 27.9212 17.44 28.113 18.4349 27.8883L24.1925 26.5877C26.8051 25.9976 29.5181 26.0134 32.1236 26.6339L42.3641 29.0729C45.4386 29.8052 48.6678 29.5172 51.5642 28.2525L52.8381 27.6963C55.7649 26.4183 59.0936 26.4267 62.0139 27.7195V27.7195C64.4889 28.8151 67.273 28.9944 69.868 28.2251L74.4322 26.8721C79.1779 25.4653 84.2424 25.5603 88.9321 27.144L91.6361 28.0572C95.7893 29.4597 100.247 29.698 104.526 28.7462L113.678 26.7103C116.672 26.0444 119.794 26.2433 122.679 27.284V27.284C126.482 28.6559 130.661 28.5537 134.393 26.9975L134.722 26.8604C138.072 25.4633 141.78 25.169 145.309 26.0202L154.623 28.267C157.768 29.0257 161.035 29.1406 164.225 28.6048L177.909 26.3071C180.262 25.9119 182.677 26.4519 184.638 27.8118V27.8118C186.826 29.3289 189.565 29.818 192.143 29.1515L204.482 25.9606C207.581 25.159 210.872 25.6028 213.648 27.1968V27.1968C216.948 29.0911 220.94 29.3448 224.453 27.8834L226.698 26.9494C230.189 25.4972 234.061 25.2365 237.715 26.2077L245.657 28.3187C250.274 29.5457 255.152 29.3672 259.666 27.8058L268.617 24.7102" stroke={isThirdItem ? colors[2] : '#404040'}/>
                    <path className={`gradient-bg ${isThirdItem ? 'active' : ''}`} d="M9.76322 30.1036L1 118.842H268.617V24.7102L259.666 27.8058C255.152 29.3672 250.274 29.5457 245.657 28.3187L237.715 26.2077C234.061 25.2365 230.189 25.4972 226.698 26.9494L224.453 27.8834C220.94 29.3448 216.948 29.0911 213.648 27.1968C210.872 25.6028 207.581 25.159 204.482 25.9606L192.143 29.1515C189.565 29.818 186.826 29.3289 184.638 27.8118C182.677 26.4519 180.262 25.9119 177.909 26.3071L164.225 28.6048C161.035 29.1406 157.768 29.0257 154.623 28.267L145.309 26.0202C141.78 25.169 138.072 25.4633 134.722 26.8604L134.393 26.9975C130.661 28.5537 126.482 28.6559 122.679 27.284C119.794 26.2433 116.672 26.0444 113.678 26.7103L104.526 28.7462C100.247 29.698 95.7893 29.4597 91.6361 28.0572L88.9321 27.144C84.2424 25.5603 79.1778 25.4653 74.4322 26.8721L69.868 28.2251C67.273 28.9944 64.4889 28.8151 62.0139 27.7195C59.0936 26.4267 55.7649 26.4183 52.8381 27.6963L51.5642 28.2525C48.6678 29.5172 45.4386 29.8052 42.3641 29.0729L32.1236 26.6339C29.5181 26.0134 26.8051 25.9976 24.1925 26.5877L18.4349 27.8883C17.44 28.113 16.3965 27.9212 15.5465 27.3574C13.1998 25.8007 10.04 27.3012 9.76322 30.1036Z" fill="url(#paint3_linear_4473_13016)"/>
                </g>
                <g className={itemClassName}>
                    <path className="chart-line" d="M1 118.843L9.62298 57.865C10.0044 55.1681 12.8166 53.5457 15.3422 54.5657V54.5657C16.2127 54.9173 17.1761 54.9664 18.0779 54.7051L22.1748 53.5182C24.3583 52.8855 26.649 52.7102 28.9034 53.0032L48.0906 55.4966C51.1021 55.8879 54.1604 55.7042 57.1033 54.9551L73.4222 50.8016C77.9652 49.6453 82.7474 49.8447 87.1783 51.3753L89.7777 52.2733C95.081 54.1052 100.859 54.0212 106.106 52.0358L107.115 51.6543C111.621 49.9495 116.556 49.7394 121.19 51.0551V51.0551C124.67 52.0431 128.337 52.1755 131.879 51.4411L138.818 50.0024C141.917 49.3599 145.113 49.3366 148.221 49.9341L157.598 51.7366C160.427 52.2804 163.331 52.3105 166.171 51.8253L181.668 49.1774C185.941 48.4474 190.334 49.2178 194.103 51.358V51.358C198.34 53.7636 203.346 54.4285 208.064 53.2122L225.641 48.6811C230.681 47.3818 236.011 47.8096 240.779 49.896V49.896C246.133 52.2389 252.173 52.4813 257.698 50.5752L269 46.6758" stroke={isSecondItem ? colors[1] : '#404040'}/>
                    <path className={`gradient-bg ${isSecondItem ? 'active' : ''}`} d="M9.62298 57.865L1 118.843H269V46.6758L257.698 50.5752C252.173 52.4813 246.133 52.2389 240.779 49.896C236.011 47.8096 230.681 47.3818 225.641 48.6811L208.064 53.2122C203.346 54.4285 198.34 53.7636 194.103 51.358C190.334 49.2178 185.941 48.4474 181.668 49.1774L166.171 51.8253C163.331 52.3105 160.427 52.2804 157.598 51.7366L148.221 49.9341C145.113 49.3366 141.917 49.3599 138.818 50.0024L131.879 51.4411C128.337 52.1755 124.67 52.0431 121.19 51.0551C116.556 49.7394 111.621 49.9495 107.115 51.6543L106.106 52.0358C100.859 54.0212 95.081 54.1052 89.7777 52.2733L87.1783 51.3753C82.7474 49.8447 77.9652 49.6453 73.4222 50.8016L57.1033 54.9551C54.1604 55.7042 51.1021 55.8879 48.0906 55.4966L28.9034 53.0032C26.649 52.7102 24.3583 52.8855 22.1748 53.5182L18.0779 54.7051C17.1761 54.9664 16.2127 54.9173 15.3422 54.5657C12.8166 53.5457 10.0044 55.1681 9.62298 57.865Z" fill="url(#paint2_linear_4473_13016)"/>
                </g>
                <g className={itemClassName}>
                    <path className="chart-line" d="M1.19141 118.844L6.7219 92.2779C7.48961 88.5902 11.5742 86.6632 14.9085 88.4158V88.4158C15.9205 88.9477 17.0696 89.1612 18.2051 89.0282L48.6 85.4687C51.6734 85.1087 54.7862 85.6167 57.5857 86.9349V86.9349C60.7565 88.428 64.3191 88.8771 67.7613 88.2178L78.9503 86.0746C82.9121 85.3158 87.0011 85.5677 90.8397 86.8071L93.0704 87.5274C96.5447 88.6492 100.229 88.9636 103.843 88.4466L123.395 85.6497C126.766 85.1674 130.202 85.4082 133.473 86.3561L138.439 87.7952C141.638 88.7221 145.064 88.4644 148.088 87.0696V87.0696C151.097 85.6814 154.505 85.4193 157.691 86.3309L161.065 87.2963C165.478 88.5588 170.165 88.4896 174.539 87.0973L174.744 87.0319C179.081 85.6511 183.722 85.5323 188.125 86.6894L193.361 88.0656C196.294 88.8364 199.347 89.0445 202.357 88.6786L228.243 85.5331C231.556 85.1305 234.915 85.6319 237.966 86.9839V86.9839C241.276 88.4506 244.943 88.9137 248.513 88.316L268.809 84.918" stroke={isFirstItem ? colors[0] : '#404040'}/>
                    <path className={`gradient-bg ${isFirstItem ? 'active' : ''}`} d="M6.95121 90.0616L1 118.649L268.617 118.453V84.7227L248.321 88.1207C244.751 88.7184 241.084 88.2552 237.775 86.7886C234.724 85.4365 231.364 84.9352 228.051 85.3377L202.165 88.4833C199.155 88.8491 196.103 88.6411 193.17 87.8703L187.933 86.494C183.531 85.337 178.89 85.4558 174.553 86.8366L174.347 86.902C169.974 88.2943 165.286 88.3635 160.874 87.101L157.499 86.1356C154.313 85.224 150.906 85.4861 147.896 86.8742C144.872 88.2691 141.447 88.5268 138.248 87.5999L133.282 86.1608C130.011 85.2129 126.575 84.9721 123.203 85.4544L103.652 88.2513C100.038 88.7683 96.3533 88.4539 92.879 87.3321L90.6483 86.6118C86.8097 85.3724 82.7206 85.1205 78.7589 85.8793L67.5699 88.0225C64.1277 88.6818 60.5651 88.2327 57.3943 86.7396C54.5948 85.4214 51.482 84.9134 48.4086 85.2733L20.064 88.5928C17.5929 88.8822 15.0921 88.4176 12.8899 87.26C10.4711 85.9886 7.50811 87.3865 6.95121 90.0616Z" fill="url(#paint1_linear_4473_13016)"/>
                </g>

                <defs>
                    <linearGradient id="paint0_linear_4473_13016" x1="135.011" y1="-330.554" x2="134.051" y2="118.839" gradientUnits="userSpaceOnUse">
                        <stop stopColor={colors[3]}/>
                        <stop offset="1" stopColor={colors[3]} stopOpacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint3_linear_4473_13016" x1="135.011" y1="-240.081" x2="134.398" y2="118.841" gradientUnits="userSpaceOnUse">
                        <stop stopColor={colors[2]}/>
                        <stop offset="1" stopColor={colors[2]} stopOpacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint2_linear_4473_13016" x1="135.202" y1="-154.057" x2="134.849" y2="118.843" gradientUnits="userSpaceOnUse">
                        <stop stopColor={colors[1]}/>
                        <stop offset="1" stopColor={colors[1]} stopOpacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear_4473_13016" x1="135.011" y1="-9.64334" x2="134.932" y2="118.649" gradientUnits="userSpaceOnUse">
                        <stop stopColor={colors[0]}/>
                        <stop offset="1" stopColor={colors[0]} stopOpacity="0"/>
                    </linearGradient>
                </defs>
            </svg>
        </>
    );
};

