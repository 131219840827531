import { useEffect, useRef, useState } from 'react';

export const useAnimatedPropsChange = <TProps>(
    props: TProps,
    exitAnimation: () => GSAPTimeline | Promise<any>,
    enterAnimation: () => GSAPTimeline | Promise<any>,
) => {

    const [state, setState] = useState<TProps | null>(null);
    const isInitialCall = useRef<boolean>(true);

    const nextState = props;

    useEffect(() => {
        if (JSON.stringify(state) === JSON.stringify(nextState)) {
            return;
        }

        if (state === null) {
            setState(nextState);
            return;
        }
        // Exit animation
        Promise.resolve(exitAnimation()).then(() => {
            setState(nextState);
            isInitialCall.current = false;
        });

    }, [state, nextState, exitAnimation]);

    useEffect(() => {
        if (state === null) {
            return;
        }

        if (isInitialCall.current) {
            return;
        }

        // Enter animation
        enterAnimation();

    }, [state, enterAnimation]);

    return [state];
};

