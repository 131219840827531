import { useMemo } from 'react';
import { DeepReadonly } from '@zajno/common/types';

import './style.sass';

import { ProductMeta } from 'common/types/models/product';
import { ChartSVG } from '../utils/chartSvg';
import { HeaderPoint } from './HeaderPoint';
import { getRangeWithStep } from 'scripts/utils/getRangeWithStep';
import { CountedNumber } from 'app/components/common/CountedNumber';

type Props = {
    className?: string,
    campaignMeta: DeepReadonly<ProductMeta>,
    activeIndex: number,
    campaignsColors: string[]
    isPremiumProduct: boolean,
};

const createHeaderPoints = (productMeta: DeepReadonly<ProductMeta>) => {
    return [
        { title: 'Guaranteed streams', value: [productMeta.guaranteedStream] },
        { title: 'Maximum stream potential', value: [productMeta.maxStreamPotential] },
        { title: 'Reach', value: [productMeta.reachDiapason.min, productMeta.reachDiapason.max] },
    ];
};

export const CampaignChart = ({
    className,
    campaignMeta,
    activeIndex,
    campaignsColors,
    isPremiumProduct,
}: Props) => {

    const headerPoints = useMemo(() => createHeaderPoints(campaignMeta), [campaignMeta]);

    const chartYpoints = useMemo(() => {
        if (isPremiumProduct) {
            return {
                desktop: [500, 400, 300, 200, 100, 0],
                responsive: [500, 300, 100, 0],
            };
        }
        return {
            desktop: [100, 80, 60, 40, 20, 0],
            responsive: [100, 60, 20, 0],
        };
    }, [isPremiumProduct]);

    const chartXpoints = useMemo(() => {
        return {
            desktop: getRangeWithStep([...campaignMeta.daysRange], 7),
            responsive: getRangeWithStep([...campaignMeta.daysRange], 4),
        };
    }, [campaignMeta]);

    return (
        <div className={`campaign-chart ${className || ''}`}>
            <div className="campaign-chart__header">
                {headerPoints.map((point, index) => (
                    <HeaderPoint
                        key={`${index}__campaign-header-point`}
                        title={point.title}
                        value={point.value}
                        withPlus={index === 1}
                    />
                ))}
            </div>
            <div className="campaign-chart__body">
                <span className="campaign-chart__body-title chart-b2">Total Streams</span>

                <div className="campaign-chart__chart">
                    <ul className="chart-y-axios desktop-axios">
                        {chartYpoints.desktop.map((num, i) => (
                            <li key={`${i}__y-point`} className="chart-y-axios__point chart-b2">
                                {(num > 0)
                                    ? (<><CountedNumber num={num}/>{'k'}</>)
                                    : num
                                }
                            </li>
                        ))}
                    </ul>
                    <ul className="chart-y-axios responsive-axios">
                        {chartYpoints.responsive.map((num, i) => (
                            <li key={`${i}__y-point`} className="chart-y-axios__point chart-b2">
                                {(num > 0)
                                    ? (<><CountedNumber num={num}/>{'k'}</>)
                                    : num
                                }
                            </li>
                        ))}
                    </ul>
                    <div className="chart-image">
                        <ChartSVG
                            colors={campaignsColors}
                            itemClassName="campaign-chart__gradient-item"
                            activeIndex={activeIndex}
                        />
                    </div>
                    <ul className="chart-x-axios desktop-axios">
                        {chartXpoints.desktop.map((num, i) => (
                            <li key={`${i}__x-point`} className="chart-x-axios__point chart-b2">
                                <CountedNumber num={num}/>{'d'}
                            </li>
                        ))}
                    </ul>
                    <ul className="chart-x-axios responsive-axios">
                        {chartXpoints.responsive.map((num, i) => (
                            <li key={`${i}__x-point-responsive`} className="chart-x-axios__point chart-b2">
                                <CountedNumber num={num}/>{'d'}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};
