import React, { useCallback, useEffect, useRef } from 'react';
import { DeepReadonly } from '@zajno/common/types';
import { gsap } from 'gsap';

import './style.sass';

import { ProductData } from 'common/types/models/product';
import { inFrames } from 'scripts/utils/inFrames';
import { useAnimatedPropsChange } from 'app/hooks/useAnimatedPropsChange';
import { BreakpointType, Breakpoints } from 'common/views/appBreakpoints';
import { hexToRgba } from 'scripts/utils/hexToRgba';
import { setCssVariable } from 'scripts/utils/setCssVariable';
import { Easings } from 'scripts/utils/tweenHelpers';
import { Routes } from 'src/types/routes';

type Props = {
    className?: string,
    activeCampaign: DeepReadonly<ProductData>,
};

const yOffsetValuesMap = {
    [BreakpointType.Desktop]: 30,
    [BreakpointType.Tablet]: 20,
    [BreakpointType.Mobile]: 20,
};

export const CampaignBuyPanel = (props: Props) => {
    const leftWrapperRef = useRef();
    const rightWrapperRef = useRef();

    const exitAnimation = useCallback(() => {
        const exitTimeline = gsap.timeline();
        const mediaQuery = Breakpoints.Current.breakpoint.name;

        exitTimeline
            .fromTo(leftWrapperRef.current, { y: 0, autoAlpha: 1 }, {
                y: -yOffsetValuesMap[mediaQuery],
                autoAlpha: 0,
                duration: inFrames(25),
                ease: Easings.chartEaseOut,
            }, 0)
            .fromTo(rightWrapperRef.current, { autoAlpha: 1 }, {
                autoAlpha: 0,
                duration: inFrames(25),
                ease: Easings.chartOpacity,
            }, inFrames(10));

        return exitTimeline;
    }, []);

    const enterAnimation = useCallback(() => {
        const enterTimeline = gsap.timeline();

        const mediaQuery = Breakpoints.Current.breakpoint.name;

        enterTimeline
            .fromTo(leftWrapperRef.current, { y: yOffsetValuesMap[mediaQuery], autoAlpha: 0 }, {
                y: 0,
                autoAlpha: 1,
                duration: inFrames(25),
                ease: Easings.chartEaseIn,
            }, 0)
            .fromTo(rightWrapperRef.current, { autoAlpha: 0 }, {
                autoAlpha: 1,
                duration: inFrames(25),
                ease: Easings.chartOpacity,
            }, 0);

        return enterTimeline;
    }, []);

    const [state] = useAnimatedPropsChange<Props>(props, exitAnimation, enterAnimation);

    const originPrice = `$${(state?.activeCampaign.prices.origin || props.activeCampaign.prices.origin)}`;
    const currentPrice = `$${(state?.activeCampaign.prices.current || props.activeCampaign.prices.current)}`;
    const theme = state?.activeCampaign.meta.color || props.activeCampaign.meta.color;
    const description = state?.activeCampaign.description || props.activeCampaign.description;

    useEffect(() => {
        setCssVariable('chart-button-default', hexToRgba(theme, 0.2));
        setCssVariable('chart-button-hover', hexToRgba(theme, 0.4));
        setCssVariable('chart-theme-color', theme);
    }, [theme]);

    return (
        <div className={`campaign-buy-panel ${props.className || ''}`}>
            <div className="left-wrapper" ref={leftWrapperRef}>
                <span className="price-text total chart-p2">
                    Total:
                </span>
                <span className="price-text campaign-title chart-p2">
                    {`${description} -`}
                </span>
                <span className="price-text origin-price chart-p2n">
                    {originPrice}
                </span>
                <span className="price-text current-price chart-p2b" style={{ color: theme }}>
                    {currentPrice}
                </span>
            </div>
            <div className="right-wrapper" ref={rightWrapperRef}>
                <a
                    className="button-s btn up-text desktop-btn chart-buy-button"
                    href={Routes.Checkout(props.activeCampaign.slug)}
                >
                    Start now
                </a>

                <a
                    className="button-s btn up-text mobile-btn chart-buy-button"
                    href={Routes.Checkout(props.activeCampaign.slug)}
                >
                    Total: {currentPrice}
                </a>
            </div>
        </div>
    );
};
