import { CountedNumber } from 'app/components/common/CountedNumber';

type HeaderPointProps = {
    title: string,
    value: number[],
    withPlus?: boolean,
};

const MIN_VAL_FOR_SHORTCUT = 500000;

const getNumberFormat = (value: number) => {
    return value > MIN_VAL_FOR_SHORTCUT ? 'letter' : 'comma';
};

export const HeaderPoint = ({ title, value, withPlus }: HeaderPointProps) => {
    return (
        <div className="campaign-chart__header-point" >
            <span className="chart-b2">{title}</span>
            <span className="campaign-chart__counter chart-p1" >
                {value.length <= 1
                    ? (<>
                        <CountedNumber num={value[0]} format={getNumberFormat(value[0])}/>
                        {withPlus && '+'}
                    </>
                    )
                    : <>
                        <CountedNumber num={value[0]} format={getNumberFormat(value[0])}/>
                        {'-'}
                        <CountedNumber num={value[1]} format={getNumberFormat(value[1])}/>
                        {'+'}
                    </>
                }
            </span>
        </div>
    );
};
