import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DeepReadonly } from '@zajno/common/types';

import './style.sass';

import fireEmoji from 'assets/img/fire-emoji.png';

import { CampaignSelectorModes } from '../viewModel';
import { ProductData } from 'common/types/models';
import { BreakpointType, Breakpoints, useGem } from 'app/services/breakpoints';

type Props = {
    className?: string,
    productsList: DeepReadonly<ProductData[]>,
    isPremiumUser: boolean,
    theme: string,
    activeIndex: number,
    onSelectCampaign: (id: string) => void,
    currentMode: CampaignSelectorModes,
    setCurrentMode: (mode: CampaignSelectorModes) => void,
};

const PaddingsMap = {
    [BreakpointType.Desktop]: 13,
    [BreakpointType.Tablet]: 12,
    [BreakpointType.Mobile]: 18,
};

export const CampaignSwitcher = (props: Props) => {
    const {
        className,
        productsList,
        theme,
        activeIndex,
        onSelectCampaign,
        currentMode,
        setCurrentMode,
        isPremiumUser,
    } = props;
    const gem = useGem();
    const [selectedBoxWidth, setSelectedBoxWidth] = useState<string>('0rem');
    const [selectedBoxPosition, setSelectedBoxPosition] = useState(3);

    const listRef = useRef<HTMLUListElement>(null);

    const hotCampaignIndex = useMemo(() => productsList.findIndex(c => c.meta.mostPopular), [productsList]);

    const handleSelectCampaign = (id: string) => {
        if (onSelectCampaign) {
            onSelectCampaign(id);
        }
    };

    useEffect(() => {
        const listSizes = listRef.current.getBoundingClientRect();
        const listItems = listRef.current.querySelectorAll('.campaign-switcher__item');
        const linkSizes = listItems[activeIndex].getBoundingClientRect();
        const currentBreakpoint = Breakpoints.Current.breakpoint.name;
        const currentPadding = PaddingsMap[currentBreakpoint] * gem;

        const newSelectedBoxPos = (linkSizes.x - listSizes.x) - currentPadding;
        const newSelectedBoxWidth = linkSizes.width + currentPadding * 2;

        setSelectedBoxWidth(`${newSelectedBoxWidth}px`);
        setSelectedBoxPosition(newSelectedBoxPos);
    }, [activeIndex, productsList, gem]);

    const selectorModes = Object.values(CampaignSelectorModes);

    return (
        <div className={`campaign-switcher ${className || ''}`}>
            <div className="campaign-switcher__title">
                {isPremiumUser
                    ? selectorModes.map((mode, index) => (
                        <React.Fragment key={`${mode}__${index}`}>
                            <h1
                                className={`chart-h1 white-orange ${currentMode === mode ? 'active' : ''}`}
                                onClick={() => setCurrentMode(mode)}
                            >
                                {mode}
                            </h1>

                            {(index !== selectorModes.length - 1) && (
                                <div className="title-divider" style={{ background: theme }}></div>
                            )}
                        </React.Fragment>
                    ))
                    : (
                        <h1
                            className="chart-h1 white-orange active"
                        >
                            {CampaignSelectorModes.Default}
                        </h1>
                    )
                }

            </div>

            <ul className="campaign-switcher__list" ref={listRef}>
                <li
                    className="campaign-switcher__selected-box"
                    style={{ width: selectedBoxWidth, left: selectedBoxPosition }}>
                </li>

                {productsList.map((product, index) => {
                    const isActive = activeIndex === index;
                    const isHotCampaign = hotCampaignIndex === index;

                    return (
                        <li
                            className={`campaign-switcher__item ${isActive ? 'active' : ''}`}
                            onClick={() => handleSelectCampaign(product.id)}
                            key={product.id}
                        >
                            <div className="text-container">
                                <span className="chart-b2">{product.name.toLowerCase()}</span>
                                {isHotCampaign && (
                                    <div className="fire-emoji">
                                        <img src={fireEmoji} alt='Fire emoji' />
                                    </div>
                                )}
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
