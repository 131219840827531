import React, { useCallback, useEffect, useRef } from 'react';
import { DeepReadonly } from '@zajno/common/types';
import { gsap } from 'gsap';
const Markdown = React.lazy(() => import('react-markdown'));

import './style.sass';

import { ProductMeta } from 'common/types/models/product';
import { CheckmarkIcon } from '../utils/checkmarkSvg';

import { inFrames } from 'scripts/utils/inFrames';
import { useAnimatedPropsChange } from 'app/hooks/useAnimatedPropsChange';
import { BreakpointType, Breakpoints } from 'common/views/appBreakpoints';
import { Easings } from 'scripts/utils/tweenHelpers';
import { setCssVariable } from 'scripts/utils/setCssVariable';

type Props = {
    className?: string,
    theme: string,
    campaignMeta: DeepReadonly<ProductMeta>,
    isPremiumProduct: boolean,
};

const yOffsetValuesMap = {
    [BreakpointType.Desktop]: 30,
    [BreakpointType.Tablet]: 20,
    [BreakpointType.Mobile]: 20,
};

export const CampaignPoints = (props: Props) => {
    const listRef = useRef<HTMLUListElement | null>(null);

    const exitAnimation = useCallback(() => {
        if (import.meta.env.SSR) {
            return Promise.resolve();
        }

        const points = listRef.current.querySelectorAll('.campaign-points__item');

        const exitTimeline = gsap.timeline();

        const mediaQuery = Breakpoints.Current.breakpoint.name;

        exitTimeline
            .fromTo(points, { y: 0, autoAlpha: 1 }, {
                y: -yOffsetValuesMap[mediaQuery],
                autoAlpha: 0,
                duration: inFrames(25),
                stagger: inFrames(1),
                ease: Easings.chartEaseOut,
            }, 0);

        return exitTimeline;
    }, []);

    const enterAnimation = useCallback(() => {
        if (import.meta.env.SSR) {
            return Promise.resolve();
        }

        const points = listRef.current.querySelectorAll('.campaign-points__item');
        const enterTimeline = gsap.timeline();

        const mediaQuery = Breakpoints.Current.breakpoint.name;

        enterTimeline
            .fromTo(points, { y: yOffsetValuesMap[mediaQuery], autoAlpha: 0 }, {
                y: 0,
                autoAlpha: 1,
                duration: inFrames(25),
                stagger: inFrames(1),
                ease: Easings.chartEaseIn,
            }, inFrames(1));

        return enterTimeline;
    }, []);

    const [state] = useAnimatedPropsChange<Props>(props, exitAnimation, enterAnimation);

    const campaignMeta = state?.campaignMeta || props.campaignMeta;
    const theme = state?.theme || props.theme;

    useEffect(() => {
        setCssVariable('chart-point-number-color', theme);
    }, [theme]);

    return (
        <div className={`campaign-points ${props.className || ''}`}>
            <div className="campaign-points__wrap">
                <ul className="campaign-points__list" ref={listRef}>
                    {campaignMeta.points.map((point, index) => (
                        <li
                            className={`campaign-points__item ${point.status ? 'active' : ''}`}
                            key={`${index}__campaign-point`}
                        >
                            <div className="campaign-points__checkmark">
                                <CheckmarkIcon fill={theme} />
                            </div>
                            <Markdown className="campaign-points__title chart-p2">
                                {point.value}
                            </Markdown>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};
